.form-control-component {
  margin-bottom: 12px;
  input {
    padding: 12px 16px;
    margin: 4px 0px;
    color: #333;
  }
  .input-icon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    margin-right: 16px;
    cursor: pointer;
    padding: 12px 0;
  }

  .inp-wrap {
    position: relative;
  }

  input:focus {
    outline: 0;
  }

  input::placeholder {
    color: transparent;
  }

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #323e45;
    margin: 4px 0px;
  }

  .form-error {
    font-size: 13px;
  }
}

.d-form-switch {
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-right: 22px;
    }
  }
}
