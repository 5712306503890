.gallery-preview-image {
  width: 242px;
  height: 136px;
  border: 1px solid #ddd;
  margin: 0 auto 12px auto;
  text-align: center;
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.wrap-editor {
  border: 1px solid #ddd;
}

.interview-preview-img {
  width: 242px;
  height: 136px;
  border: 1px solid #ddd;
  margin: 0 auto 5px auto;
  text-align: center;
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.gallery{
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;

  .thumbnail {
    max-height: 320px;
    border-radius: 12px;
    border: 1px solid #ddd;
    padding: 10px 10px;
    margin-bottom: 12px;
  }
}

.preview-video {
  display: flex;
  margin: 10px 0 24px 0;

  video {
    margin: 0 auto;
  }
}