.modal-header {
  align-items: center;
}
h5 {
  &.modal-title {
    font-size: 1.25rem;
  }
}

.dropzone-wrap {
  width: 100%;
  display: flex;
  border-radius: 8px;
  border: 2px dashed #438dff;
  padding: 25px 10px 16px;
  cursor: pointer;
}

.dropzone-area-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 64px;
    margin-bottom: 12px;
  }

  h4 {
    color: #438dff;
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  p {
    color: #bdbebf;
    margin-bottom: 0;
  }
}

.video-file-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #8ab5f7;
  border-radius: 8px;
  padding: 8px 5px;
  margin: 12px 0;

  img {
    width: 32px;
  }

  .btn-file-remove, .btn-upload-success {
    border: 0;
    background-color: transparent;

    img {
      width: 24px;
    }
  }
}

.video-file-box-desc {
  flex: 1;
  padding: 0 10px;
  font-size: 12px;
  line-height: 1.3;

  span {
    color: #a7a8a8;
  }
}

.uploader-progress-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 2px dashed #b7b7b7;
  padding: 25px 10px 16px;

  h5 {
    font-size: 16px;
    margin-top: 16px;
  }
}

.circle-uploader-progress-wrap {
  width: 110px;
}