//
// Footer
//

@import "footers/footer";

.footer {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}